import React from 'react';

import './PageTemplate.css';

const PageTemplate = ({title, children}) => {
    return (
        <div className="page-container">
            <h1 className="page-title">
                <div>{title}</div>
            </h1>
            <section className="page-content">
                {children}
            </section>
        </div>
    );
}

export default PageTemplate;
