import React from 'react';
import { Link } from 'react-router-dom';

import picture from './img/form-generator.jpg'
import './ProjectTemplate.css';

const FormGeneratorPage = () => {
    return (
        <article className="project-page">
            <h1 className="project-title">
                <div>Form generator</div>
            </h1>

            <div className="project-content">
                <section>
                    <h4 className="project-subtitle">Project background</h4>
                    <p> PredictX (former PI Ltd) delivers data analysis for clients from different sectors like travel,
                        care and health, retail and procurement. They deliver solutions for such companies as Zurich,
                        Stonegate, Pfizer, Mondelez and many more. Costumers of PredictX collect huge amount of data and
                        need analytics done based on them. Solution that is delivered by PredictX in most of the cases
                        have two parts: forms that allow data collection and complex analytics based on collected data.
                        Project management in the company is very dynamic. Company needed a solution that would allow
                        building forms for clients rapidly and often by data analysts who have limited Computer Science
                        knowledge. For that reason our team designed and built automatic form generator. We created a
                        solution that was both reusable for different clients with different requirements and was easily
                        managed by data analysts.
                        <br/>
                        Automatic form builder consists of 3 components: creation of a form template, creation of a form
                        based on template and management of all created forms. Forms creation and display was driven by
                        JSON configuration.
                    </p>
                </section>

                <section>
                    <h4 className="project-subtitle">Technologies and tools</h4>
                    <p><strong>AngularJS</strong> (version 1.0),
                        <strong> AngularFormly</strong>,
                        <strong> LumX</strong>,
                        <strong> Sass</strong>,
                        <strong> Bower</strong>,
                        <strong> NPM</strong>,
                        <strong> Jasmine</strong>,
                        <strong> Karma</strong>,
                        <strong> Git</strong>
                    </p>
                </section>

                <figure className="project-figure">
                    <img className="project-image project-image--limited"
                         src={picture}
                         alt="Form generator application layers"/>
                    <figcaption>Layers of application</figcaption>
                </figure>

                <section>
                    <h4 className="project-subtitle">My responsibilities</h4>
                    <p>As a team member I was involved in deciding new front-end architecture in a company. I designed
                        mockups using Photoshop and created prototypes in Angular. I created unit tests and
                        documentation. I worked with back-end developers, QA experts and data analysts in order to
                        gather requirements for final solution.
                    </p>
                </section>
            </div>

            <div className="go-back-link-container">
                <Link className="go-back-link" to="/projects">Back to projects</Link>
            </div>
        </article>
    );
}

export default FormGeneratorPage;
