import React from 'react';

import PageTemplate from './../PageTemplate/PageTemplate.jsx'
import Laptop from '../../components/Laptop/Laptop.jsx'
import './AboutMePage.css';

const AboutMePage = () => {
    return (
        <PageTemplate title="About me">
            <h4 className="paragraph-title">About me</h4>
            <section className="paragraph-content">
                <p>
                    I’m a front-end developer based in London.
                    I specialise in creating interactive and user friendly interfaces.
                    My solid Computer Science background (<strong>MSc in Computer Science</strong> and <strong>
                    5+ years of experience</strong> firstly as a back-end and later as a front-end developer) and
                    understanding of aesthetic that I gained during my art studies (University of Art London and
                    Barcelona Academy of Art) help me to create both well written and beautiful web applications.
                </p>
                <p>
                    I'm focusing mainly on development but from time to time I enjoy designing my own projects
                    and explore new visual trends.
                </p>
            </section>
            <h4 className="paragraph-title">Technologies</h4>
            <section className="paragraph-content flex" >
                <ul>
                    <li>HTML5</li>
                    <li>CSS3</li>
                    <li>ES6</li>
                    <li>SASS</li>
                    <li>React</li>
                    <li>D3</li>
                </ul>
                <Laptop />
            </section>
        </PageTemplate>
    );
}

export default AboutMePage;
