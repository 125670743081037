import React from 'react';
import { Link } from 'react-router-dom';

import picture from './img/vc-create-event.jpg'
import './ProjectTemplate.css';

const VoyageControlPage = () => {
    return (
        <article className="project-page">
            <h1 className="project-title">
                <div>Voyage Control</div>
            </h1>
            <div className="project-content">
                <section>
                    <h4 className="project-subtitle">Project background</h4>
                    <p>Voyage Control is a company that delivers logistic management solution in many sectors starting
                        from transport, through events management and ending on port terminals. When I joined, the
                        company was in the process of designing new architecture of application divided into separate
                        front-end and back-end. Our team redesigned and improved the biggest part of system - events
                        management component and created new features like user and role management. </p>
                </section>

                <section>
                    <h4 className="project-subtitle">Technologies and tools</h4>
                    <p>Front-end of the project was developed using <strong>AngularJS</strong> and
                        <strong> Bootstrap</strong> as a component library.
                        Styles were managed with <strong>Sass</strong>. Tests were written using
                        <strong> Jasmine</strong>, <strong>Karma</strong> (for unit tests) and
                        <strong> Protractor</strong> (for e2e tests). In development process we used
                        <strong> Bower</strong>, <strong>Grunt</strong>, <strong>NPM</strong> and <strong>Git</strong>.
                        Client-server communication was implemented using REST.
                    </p>
                </section>

                <figure className="project-figure">
                    <img className="project-image project-image--limited project-image--with-shadow"
                         src={picture} alt="Create event project screenshot"/>
                    <figcaption>Screenshot from create event feature</figcaption>
                </figure>

                <section>
                    <h4 className="project-subtitle">My responsibilities</h4>
                    <p>I was responsible for redesigning of user interface from visual point of view and designing and
                        building solution from technical point of view. As a part of my daily tasks I designed and
                        created mockups for the new UI features. I analysed existing functionality and agreed with
                        product owner about changes. I implemented aligned solutions and I created unit and e2e tests.
                    </p>
                </section>
            </div>

            <div className="go-back-link-container">
                <Link className="go-back-link" to="/projects">Back to projects</Link>
            </div>
        </article>
    );
}

export default VoyageControlPage;
