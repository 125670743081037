import React, { Component } from 'react';

import PageTemplate from './../PageTemplate/PageTemplate.jsx'

import Envelope from './Envelope/Envelope.jsx';
import SocialLink from './SocialLink/SocialLink.jsx';
import StatusMessage from './StatusMessage/StatusMessage.jsx';
import './ContactPage.css';

const serverURL = '/form-contact';

class ContactPage extends Component {

    state = {
        formStatus: 'OK',
        isStatusVisible: false
    }

    sendMessage = (e) => {
        e.preventDefault();

        const formData = new FormData(document.getElementById('contactForm'));
        let formObject = {};
        formData.forEach((value, key) => {
            formObject[key] = value;
        });

        fetch(serverURL,
            {
                method: 'POST',
                body: JSON.stringify(formObject),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(() => {
                    this.setStatusAndCleanForm('OK');
                },
                () => {
                    this.setStatusAndCleanForm('ERROR');
                })
            .then(() => this.removeMessage());
    }

    setStatusAndCleanForm(status) {
        this.setState({
            formStatus: status,
            isStatusVisible: true
        });
        document.getElementById("contactForm").reset();
    }

    removeMessage() {
        setTimeout(() => this.setState({isStatusVisible: false}), 5000)
    }

    render() {
        const { formStatus, isStatusVisible } = this.state;
        return (
            <PageTemplate title="Get in touch">
                <div className="contact-page">
                    <div className="contact-picture">
                        <Envelope />
                    </div>
                    <div className="contact-form">
                        <form id="contactForm" onSubmit={this.sendMessage}>
                            <div className="form-field">
                                <label htmlFor="name">Name</label>
                                <input type="text" id="name" name="name" required/>
                            </div>

                            <div className="form-field">
                                <label htmlFor="email">Email</label>
                                <input type="email" id="email" name="email" required/>
                            </div>

                            <div className="form-field">
                                <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" style={{height:'200px'}} required>
                        </textarea>
                            </div>
                            <div className="form-field">
                                <button className="submit-btn"
                                        type="submit">send
                                </button>
                            </div>
                        </form>

                        <StatusMessage status={formStatus} isStatusVisible={isStatusVisible}/>

                        <div className="social-links">
                            <div className="social-links-desc">You can also find me</div>
                            <div className="social-links-icons">
                                <SocialLink logo="linkedIn"/>
                                <SocialLink logo="angelList"/>
                                <SocialLink logo="codePen"/>
                                <SocialLink logo="skype"/>
                            </div>
                        </div>
                    </div>
                </div>

            </PageTemplate>
        );
    }
}

export default ContactPage;
