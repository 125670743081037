import React from 'react';
import { Link } from 'react-router-dom';

import picture from './img/scifail.jpg';
import './ProjectTemplate.css';

const ScifailPage = () => {
    return (
        <article className="project-page">
            <h1 className="project-title">
                <div>Scifail</div>
            </h1>
            <div className="project-content">
                <section>
                    <h4 className="project-subtitle">Project background</h4>
                    <p> This is my most recent project. The goal of application is to help in experimenting process in
                        organic chemistry. Application collects experiments results, which can be later seen and
                        commented by other users. Key element of the application is easily searchable data base,
                        providing quick access to the information with unsuccessful chemical experiments or experiments
                        that went differently as described in the literature.
                    </p>
                </section>

                <section>
                    <h4 className="project-subtitle">Technologies and tools</h4>
                    <p>Front-end of the project was developed using <strong>React</strong> as main framework and
                        <strong> Mobx</strong> for state
                        management. Styles were written using <strong>Sass</strong>. For tests we used
                        <strong>Enzyme</strong>,<strong> Jest</strong> and <strong>Cypress</strong>, for e2e
                        tests. In a development process we used <strong>WebPack</strong>, <strong>Babel</strong>,
                        <strong>yarn</strong> and <strong>Git</strong>.
                    </p>
                </section>

                <figure className="project-figure">
                    <img className="project-image project-image--limited project-image--with-shadow"
                         src={picture}
                         alt="Creation of new experiment"/>
                    <figcaption>Creation of new experiment</figcaption>
                </figure>

                <section>
                    <h4 className="project-subtitle">My responsibilities</h4>
                    <p>I was responsible for whole process starting from designing mockups and ending on development of
                        front-end.
                    </p>
                </section>

            </div>
            <div className="go-back-link-container">
                <Link className="go-back-link" to="/projects">Back to projects</Link>
            </div>
        </article>
    );
}

export default ScifailPage;
