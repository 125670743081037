import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { CSSTransition } from 'react-transition-group'

import './Menu.css';

const links = [
    {
        to: '/',
        label: 'Home'
    },
    {
        to: '/about-me',
        label: 'About me'
    },
    {
        to: '/projects',
        label: 'Projects'
    },
    {
        to: '/contact',
        label: 'Contact'
    }

];

const animationTimeout = 300;

class Menu extends Component {
    state = {
        isMenuOpen: false,
        isCloseButton: false
    };

    toggleMenu = () => {
        this.setState(prevStage => ({
            isMenuOpen: !prevStage.isMenuOpen
        }))
    }

    toggleCloseButton = () => {
        this.setState(prevStage => ({
            isCloseButton: !prevStage.isCloseButton
        }))
    }

    render () {
        const { isMenuOpen, isCloseButton } = this.state;
        const menuClassNames = 'menu-btn' + (isCloseButton ? ' menu--open': '')
        const ariaLabelBtn = isCloseButton ? 'Close navigation menu': 'Open navigation menu';
        return (
            <div>
                <CSSTransition
                    in={isMenuOpen}
                    timeout={animationTimeout}
                    classNames="slide-menu-btn"
                    onEntered={this.toggleCloseButton}
                    onExited={this.toggleCloseButton}>
                    <button className={menuClassNames}
                            aria-label={ariaLabelBtn}
                            aria-expanded={isMenuOpen}
                            onClick={this.toggleMenu}>
                        <span>Menu</span>
                    </button>
                </CSSTransition>

                <CSSTransition
                    in={isMenuOpen}
                    timeout={animationTimeout}
                    classNames="slide-menu"
                    unmountOnExit >

                        <nav className="page-menu">
                            <ul>
                                { links.map((link) =>
                                    <li className="nav-menu-item"
                                        key={link.label} >
                                        <NavLink to={link.to}
                                                 exact
                                                 activeClassName='is-active'
                                                 onClick={this.toggleMenu}>
                                            {link.label}
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </nav>
                </CSSTransition>
            </div>
    )}
}
export default Menu;
