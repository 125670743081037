import React from 'react';
import Rectangle from './../../components/Rectangle/Rectangle.jsx';
import PageTemplate from './../PageTemplate/PageTemplate.jsx'

import './ProjectsPage.css';

const Projects = () => {
    return (
        <PageTemplate title="Recent projects">
            <div className="honeycomb">
                <div className="comb-row">
                    <Rectangle logo="scifail"
                               link="/projects/scifail"
                               desc="Scifail" />
                    <Rectangle logo="networkMonitoring"
                               link="/projects/network-monitoring"
                               desc="Network Monitoring" />
                </div>
                <div className="comb-row">
                    <Rectangle logo="voyageControl"
                               link="/projects/voyage-control"
                               desc="Voyage Control" />
                    <Rectangle logo="formGenerator"
                               link="/projects/form-generator"
                               desc="Form Generator" />
                </div>
            </div>
        </PageTemplate>
    );
}

export default Projects;
