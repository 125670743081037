import React from 'react';
import { Link } from 'react-router-dom';

import picture from './img/network-monitoring.jpg'
import './ProjectTemplate.css';

const FormGeneratorPage = () => {
    return (
        <article className="project-page">
            <h1 className="project-title">
                <div>Network Monitoring</div>
            </h1>
            <div className="project-content">
                <section>
                    <h4 className="project-subtitle">Project background</h4>
                    <p> Company for which I worked built solution for optimisation of the traffic in the Internet. I
                        participated in the building and designing of the first phase of system, where customers could
                        monitor their internal and external Internet traffic. The key objective was to create clear and
                        easy to understand and analyse visualisation of current and past traffic and possibility to
                        compare them together.
                    </p>
                </section>

                <section>
                    <h4 className="project-subtitle">Technologies and tools</h4>
                    <p>Project was developed using <strong>ReactJS</strong>, <strong>Mobx</strong> as a base frameworks.
                        Data visualisation was done in
                        pure <strong>D3.js</strong> and <strong>@vx</strong>, which is a collection of data
                        visualisation components build base on
                        combination of D3 and React. For styles management we used <strong>SASS</strong>. In a
                        development process we
                        used <strong>WebPack</strong>, <strong>Babel</strong>, <strong>yarn</strong> and
                        <strong> Git</strong>.
                    </p>
                </section>

                <figure className="project-figure">
                    <img className="project-image project-image--limited project-image--with-shadow"
                         src={picture}
                         alt="Example screenshot achievable with technologies mentioned above"/>
                    <figcaption>Application is still in development process and company does not want to show it
                        publicly yet. A picture above is example of what can be achieved with mentioned
                        technologies, and is part of my personal project.
                    </figcaption>
                </figure>

                <section>
                    <h4 className="project-subtitle">My responsibilities</h4>
                    <p>My main responsibility was developing front-end of the application. But I participated as well in
                        process of designing mockups for the new user interface in Figma and Marvel. I gathered
                        requirements and discussed designed solutions with other members of company. As a member of
                        front-end team I was also involved in process of designing technical solutions and choosing
                        frameworks and tools that let us achieved designed goals.
                    </p>
                </section>
            </div>

            <div className="go-back-link-container">
                <Link className="go-back-link" to="/projects">Back to projects</Link>
            </div>
        </article>
    );
}

export default FormGeneratorPage;
