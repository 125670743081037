import React from 'react';

import Circle from './../../components/Circle/Circle.jsx';
import './LandingPage.css';

const LandingPage = () => {
        return (
            <div>
                <Circle key="circle" className="circle" />
                <div key="landing-text" className="landing-text-container">
                    <h1 className="landing-text">
                        Hello, <br /> I’m Dominika Gluchowska
                    </h1>
                    <h3 className="landing-subtext">
                        I’m a front end developer with 5+ years of experience.
                    </h3>
                </div>
            </div>
        );
}

export default LandingPage;