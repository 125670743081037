import React from 'react';
import './Envelope.css';

const Envelope = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 392 346"
             className="envelop-svg"
             role="img"
             aria-labelledby="envelopSvgTitle" >
            <title id="envelopSvgTitle">Envelop created from fine lines</title>
            <defs>
                <path id="envelop-path" d="M2 84.129L339.126 40M2 84.129L22.8405 218.968L40.0033 344M2 84.129L142.98 197.118M339.126 40C339.126 49.8065 355.472 163.398 363.645 218.968L371 297.419M339.126 40L247.183 194.452M371 297.419L40.0033 344M371 297.419L247.183 194.452M40.0033 344L142.98 197.118M247.183 194.452C240.236 205.484 222.419 227.548 206.728 227.548C191.036 227.548 170.767 217.742 162.595 212.839L142.98 197.118" />
            </defs>
            <use className="envelop1" xlinkHref="#envelop-path" />
            <use className="envelop2" xlinkHref="#envelop-path" x="10" y="-20" />
            <use className="envelop3" xlinkHref="#envelop-path" x="10" y="-40" transform="scale(1.03 1.05)" />
        </svg>

    );
}

export default Envelope;


