import React from 'react';
import './Laptop.css';

const Laptop = () => {
    return (
        <svg className="laptop-svg" viewBox="0 0 475 319" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="laptop1" d="M414.106 206.167L449.03 30L195.247 21.8333L151.01 192.167C151.01 192.167 415.27 234.167 424.583 228.333C433.896 222.5 463 21.8333 463 21.8333L454.851 9L195.247 2L180.114 9L132.384 200.333L424.583 242.333L405.957 248.167L118.414 206.167L53.2222 222.5L347.75 277.333L296.528 300.667L14.8056 234.167L2 240L4.32828 248.167L288.379 317L312.826 311.167L400.136 270.151M400.136 270.151L424.583 258.667H414.106L400.136 270.151Z" />
            <path id="laptop2" d="M424.106 206.167L459.03 30L205.247 21.8333L161.01 192.167C161.01 192.167 425.27 234.167 434.583 228.333C443.896 222.5 473 21.8333 473 21.8333L464.851 9L205.247 2L190.114 9L142.384 200.333L434.583 242.333L415.957 248.167L128.414 206.167L63.2222 222.5L357.75 277.333L306.528 300.667L24.8056 234.167L12 240L14.3283 248.167L298.379 317L322.826 311.167L410.136 270.151M410.136 270.151L434.583 258.667H424.106L410.136 270.151Z" />
        </svg>
    );
}

export default Laptop;
