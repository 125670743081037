import React from 'react';
import {CSSTransition} from 'react-transition-group';
import './StatusMessage.css'

const animationTimeout = 350;

const StatusMessage = ({ status, isStatusVisible }) => {
    return (
    <CSSTransition
        in={isStatusVisible}
        timeout={animationTimeout}
        classNames="fade-status"
        unmountOnExit >
        <h4 role="status" className="form-status">
            {
                status === 'OK' ?
                    <div className="form-status__message">
                        <svg width="30" height="30" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
                            <path d="M40.25 13.4169L17.25 36.4169L6.70833 25.8752L9.41083 23.1727L17.25 30.9927L37.5475 10.7144L40.25 13.4169Z" />
                        </svg>
                        <span>message sent</span>
                    </div>:
                    <div className="form-status__message form-status__message--error">
                        <svg width="30" height="30" viewBox="0 0 24 24" role="img">
                            <path d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
                        </svg>
                        <span>message NOT sent</span>
                    </div>
            }
        </h4>
    </CSSTransition>

    );
}

export default StatusMessage;