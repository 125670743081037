import React from 'react';
import './Circle.css';

const Circle = () => {
    return (
    <svg viewBox="0 0 638 590"
         xmlns="http://www.w3.org/2000/svg"
         className="circles"
         role="img"
         aria-labelledby="circleSvgTitle">
        <title id="circleSvgTitle">Circle created from fine lines</title>
        <g className="all-circles-group" >
            <path className="circle1" d="M625.555 295.65C625.555 449.41 491.227 574.182 325.365 574.182C159.503 574.182 25.1753 449.41 25.1753 295.65C25.1753 141.889 159.503 17.1172 325.365 17.1172C491.227 17.1172 625.555 141.889 625.555 295.65Z" />
            <path className="circle2" d="M625.555 263.415C625.555 340.39 598.713 421.137 551.147 482.624C503.589 544.099 435.363 586.27 352.563 586.27C269.739 586.27 193.183 544.074 137.308 482.563C81.4306 421.049 46.3296 340.317 46.3296 263.415C46.3296 186.376 65.5241 120.784 105.344 74.4664C145.143 28.1727 205.659 1 288.598 1C371.566 1 455.847 28.1958 519.365 74.6219C582.88 121.045 625.555 186.627 625.555 263.415Z" />
            <path className="circle3" d="M591.81 249.313C591.81 326.215 556.481 407.453 501.239 469.475C445.996 531.497 370.952 574.182 291.62 574.182C212.088 574.182 145.482 553.513 98.7756 513.702C52.0912 473.909 25.1753 414.897 25.1753 337.957C25.1753 260.984 52.1183 180.738 98.9202 119.753C145.716 58.7757 212.307 17.1172 291.62 17.1172C371.166 17.1172 446.246 36.7864 501.409 75.6394C556.54 114.47 591.81 172.474 591.81 249.313Z" />
            <path className="circle4" d="M636.636 297.664C636.636 451.424 502.308 576.197 336.446 576.197C170.584 576.197 36.2563 451.424 36.2563 297.664C36.2563 143.904 170.584 19.1318 336.446 19.1318C502.308 19.1318 636.636 143.904 636.636 297.664Z" />
            <path className="circle5" d="M606.92 265.43C606.92 342.473 587.47 423.235 547.323 484.703C507.196 546.141 446.417 588.284 363.644 588.284C280.751 588.284 190.057 550.436 120.076 493.29C50.081 436.131 1 359.831 1 283.058C1 129.285 133.831 3.01453 299.679 3.01453C465.655 3.01453 606.92 111.769 606.92 265.43Z" />
            <path className="circle6" d="M591.81 339.972C591.81 416.845 559.28 475.859 506.921 515.681C454.533 555.526 382.235 576.197 302.701 576.197C194.146 576.197 134.724 541.354 102.345 487.505C69.8916 433.532 64.4614 360.261 64.4614 283.058C64.4614 206.029 84.3815 144.737 124.038 102.706C163.685 60.6845 223.199 37.7673 302.701 37.7673C382.354 37.7673 454.573 48.351 506.892 91.3061C559.168 134.226 591.81 209.671 591.81 339.972Z" />
        </g>
    </svg>
    );
}

export default Circle;
