import React, { Component } from 'react';
import { CSSTransition, TransitionGroup} from 'react-transition-group';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ScrollToTop from './components/ScrollToTop.jsx';
import LandingPage from './sites/LandingPage/LandingPage.jsx';
import AboutMePage from './sites/AboutMePage/AboutMePage.jsx';
import ProjectPage from './sites/ProjectsPage/ProjectsPage.jsx';
import FormGeneratorPage from './sites/projects/FormGeneratorPage.jsx';
import VoyageControlPage from './sites/projects/VoyageControlPage.jsx';
import NetworkMonitoringPage from './sites/projects/NetworkMonitoringPage.jsx';
import ScifailPage from './sites/projects/SciFailPage.jsx';
import ContactPage from './sites/ContactPage/ContactPage.jsx';

import Logo from './components/Logo/Logo.jsx'
import Menu from './components/Menu/Menu.jsx'
import Arrow from './components/Arrow/Arrow.jsx'

import './styles/fonts.css';
import './styles/reset.css';
import './styles/base.css';
import './App.css';

class Root extends Component {
    render() {
        return (
            <Router>
                <ScrollToTop>
                    <Route component={App} />
                </ScrollToTop>
            </Router>
        )
    }
};

class App extends Component {

    render() {
        const {location} = this.props;
        const isAbsolutePositioned = location.pathname === '/projects';
        /* TODO I prefer it done other way */
        const swipeContainerClasses = 'swipe-container' + (isAbsolutePositioned ? ' position-absolute' : '')
        return (
            <div className="main-page">
                <header className="site-header">
                    <Logo />
                    <Menu location={location}/>
                </header>

                {/*<TransitionGroup>
                 <CSSTransition
                 key={location.key}
                 classNames="swipe"
                 timeout={1000}>*/}
                <main className={swipeContainerClasses}>
                    <Switch location={location}>
                        <Route path="/" exact component={LandingPage}/>
                        <Route path="/about-me" exact component={AboutMePage}/>
                        <Route path="/projects" exact component={ProjectPage} />
                        <Route path="/projects/form-generator" exact component={FormGeneratorPage} />
                        <Route path="/projects/voyage-control" exact component={VoyageControlPage} />
                        <Route path="/projects/network-monitoring" exact component={NetworkMonitoringPage} />
                        <Route path="/projects/scifail" exact component={ScifailPage} />
                        <Route path="/contact" exact component={ContactPage} />
                    </Switch>
                </main>
                {/* </CSSTransition>
                 </TransitionGroup>*/}

                {/*<footer className="site-footer">
                 <Arrow onClick={this.props.nextButtonAction}/>
                 </footer>*/}
                <div className="line vertical-line"></div>
                <div className="line horizontal-line"></div>
                {/*<footer className="site-footer">
                 <small>&copy; Copyright 2019, dominika-g. All Rights Reserved.</small>
                 </footer>*/}
            </div>

        )
    }
}

export default Root;
